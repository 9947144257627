// import "./src/styles/global.css"
import "./src/styles/custom.less"
import "./src/styles/page.less"
// import "./src/js/TweenMax.min.js"
// import "./src/js/ScrollMagic.min.js"
// import "./src/js/animation.gsap.min.js"

import $ from "jquery";

// import ScrollMagic from 'scrollmagic'

// import { TweenMax } from "gsap"
// import { Tween } from "react-gsap";
// import { Controller, Scene } from "react-scrollmagic"






// or:
// require('./src/styles/global.css')

$(document).ready(function () {

    console.log('REACHED');
 console.log($('.bm-burger-button'));
    setTimeout(function(){
        console.log($('.bm-burger-button'));
    },3000);
   

    // Script to stop the zoom effect in the media devices
    document.documentElement.addEventListener('touchstart', function (event) {
        if (event.touches.length > 1) {
            event.preventDefault();
        }
    }, false);


    // arrow down home page
    $('.movedown').click(function () {
        $('html, body').animate({
            scrollTop: $($(this).attr('href')).offset().top
        }, 500);
        return false;
    });


    // header scroll sticky
    $(window).scroll(function () {
        if ($(this).scrollTop() > 10) {
            $('body').addClass('stick-header');
        } else {
            $('body').removeClass('stick-header');
        }
    });

    // Scroll-to-Top Script

    $(window).scroll(function () {
        if ($(this).scrollTop() > 250) {
            $('.case-scroll').fadeIn();
        } else {
            $('.case-scroll').fadeOut();
        }
    });

    $('.packaging-tabs > li > a').click(function(event){
        event.preventDefault();
        var active_tab_selector = $('.packaging-tabs > li.active > a').attr('href');
        var actived_nav = $('.packaging-tabs > li.active');
        actived_nav.removeClass('active');
        $(this).parents('li').addClass('active');
        
        $(active_tab_selector).removeClass('active');
        $(active_tab_selector).addClass('hide');
        
        //show target tab content
        var target_tab_selector = $(this).attr('href');
        $(target_tab_selector).removeClass('hide');
        $(target_tab_selector).addClass('active');
    });

    
    $('.creative_design_page .ser_sec_row_rt  a').click(function () {
        $('html, body').animate({
            scrollTop: $($(this).attr('href')).offset().top -100
        }, 500);
        return false;
    });

   
    // var controller = new ScrollMagic.Controller();

    // var tween16 = TweenMax.staggerFromTo(".cat-sec.cat-right img", 0.5, { right: -50, opacity: 0 }, { delay: 0, right: 0, opacity: 1, ease: 'Linear.easeOut' }, .5);
    // var scene16 = new ScrollMagic.Scene({ triggerElement: ".cat-sec.cat-right img", offset: -100, reverse: true })
    //     .setTween(tween16)
    //     .addTo(controller);
   

});


